import React from "react"
import { SelectCity } from "../components/select"
import Layout from "../components/layout"
import { useHousesList } from "../hooks"

import { HouseCard } from "../components/card"

import "../styles/common/houses-list.scss"
import CountLabel from "../components/label/count-label"

const AllHousesPage = ({ location }) => {
  const houses = useHousesList(100)

  const liveReadyHouses = houses
    .filter(h => !h.archived && !h.building)
    .sort((a, b) => {
      return a.isFirst === b.isFirst ? 0 : a.isFirst ? -1 : 1
    })
  const liveBuildingHouses = houses
    .filter(h => !h.archived && h.building)
    .sort((a, b) => {
      return a.isFirst === b.isFirst ? 0 : a.isFirst ? -1 : 1
    })

  const cities = [...new Set(houses.map(h => h.city.name))]
  const title = "Купити будинок " + new Date().getFullYear()
  const description = `${cities.join(", ")}`

  return (
    <Layout
      contentClassName="layout-content"
      title={title}
      description={description}
      location={location}
    >
      <h1 className="city-houses-header">
        <SelectCity />
      </h1>

      {liveReadyHouses.length > 0 && (
        <React.Fragment>
          <div className="card-list-header">
            <h2>Готові будинки</h2> <CountLabel text={liveReadyHouses.length} />
          </div>
          <div className="card-list">
            {liveReadyHouses.map(house => (
              <HouseCard house={house} key={house.slug} />
            ))}
          </div>
        </React.Fragment>
      )}
      {liveBuildingHouses.length > 0 && (
        <React.Fragment>
          <div className="card-list-header">
            <h2>Скоро в продажі</h2>{" "}
            <CountLabel text={liveBuildingHouses.length} />
          </div>
          <div className="card-list">
            {liveBuildingHouses.map(house => (
              <HouseCard house={house} key={house.slug} />
            ))}
          </div>
        </React.Fragment>
      )}
    </Layout>
  )
}

export default AllHousesPage
